import React from 'react'
import { IonApp, IonRouterOutlet, setupIonicReact } from '@ionic/react'
import {IonReactRouter} from '@ionic/react-router';
import {Route} from 'react-router'
import Welcome from './pages/Welcome';
import Home from './pages/Home';
import Organization from './pages/Organization';
import OrganizationCreator from './pages/OrganizationCreator';
import AuthenticatedRoute from './components/AuthenticatedRoute';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/typography.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/float-elements.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/display.css'

/* Theme variables */
import './theme/main.scss'
import {connect} from './data/connect';
import {AppContextProvider} from './data/AppContext';
import NotificationsResponder from './components/Notifications/NotificationsResponder'
import HomeRouteRedirect from './components/HomeRouteRedirect'
import DeepLinksListener from './services/DeepLinksListener';
import ResetPassword from './pages/ResetPassword';
import AcceptInvitation from './pages/AcceptInvitation';
import {TokenState} from './data/persistent/persistent.state';
import {Redirect} from 'react-router-dom';
import InviteSplash from './pages/InviteSplash';

setupIonicReact()

interface StateProps {
	tokenData?: TokenState
}

interface DispatchProps {
}

interface IonicAppProps extends StateProps, DispatchProps {
}

const IonicApp: React.FC<IonicAppProps> = ({tokenData}) => {
	return (
		<IonApp>
			<IonReactRouter>
				<DeepLinksListener/>
				<NotificationsResponder/>
				<IonRouterOutlet id="main">
					<Route path="/reset-password/:token" render={() => <ResetPassword/>}/>
					<Route path="/accept-invitation/:token" render={() => <AcceptInvitation/>}/>
					<Route path="/welcome" render={() => <Welcome/>}/>
					<Route path="/invite-splash/:token" render={() => <InviteSplash/>}/>
					<AuthenticatedRoute path="/home*" render={() => <Home/>}/>
					<AuthenticatedRoute path="/organization" render={() => <Organization/>}/>
					<AuthenticatedRoute path="/organization-creator" render={() => <OrganizationCreator/>}/>
					<Redirect path={`/home/user/:userId`} to={'/home/dashboard/user/:userId'} />
					<Redirect path={"/invite/:token"} to={"/welcome/sign-up"}/>
					<Redirect path={"/business/:businessId/location/:locationId"} to={`${tokenData ? '/home/dashboard': '/welcome'}/business/:businessId/location/:locationId`}/>
					<Redirect path={"/post/:postId"} to={`${tokenData ? '/home/dashboard': '/welcome'}/post/:postId`}/>
					<Route exact path='/'>
						<HomeRouteRedirect/>
					</Route>
				</IonRouterOutlet>
			</IonReactRouter>
		</IonApp>
	)
}

const IonicAppConnected = connect<{}, StateProps, DispatchProps>({
	mapStateToProps: (state) => ({
		tokenData: state.persistent.tokenData
	}),
	mapDispatchToProps: { },
	component: IonicApp
});

const App: React.FC = () => {
	return (
		<AppContextProvider>
			<IonicAppConnected />
		</AppContextProvider>
	);
};

export default App;

