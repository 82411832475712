import React, {useEffect, useState} from 'react';
import {IonButton, IonContent, IonIcon, IonItem, IonMenu} from '@ionic/react';
import './index.scss';
import {useHistory, useLocation} from 'react-router';
import {marketingSiteUrl} from "../../../config";

interface WelcomeMenuLinkProps {
    url: string,
    onClick?: () => void,
}

const WelcomeMenuLink: React.FC<WelcomeMenuLinkProps> = ({url, onClick, children}) => {

    return (
        <IonItem lines={'none'} routerLink={onClick ? undefined : url} onClick={onClick} className={window.location.pathname == url ? 'active' : ''}>
            {children}
        </IonItem>
    )
}
interface WelcomeMenuProps {
    disabled: boolean,
}

const WelcomeMenu: React.FC<WelcomeMenuProps> = ({disabled}) => {

    const location = useLocation();

    return (
        <IonMenu className={'welcome-desktop-menu'} contentId={'welcome'} disabled={disabled}>
            <IonContent>
                {location.pathname.indexOf('post') !== -1 ?
                    <React.Fragment>
                        <IonItem className={'active'} lines={'none'}>
                            <IonIcon src={'/assets/app-icons/browse.svg'}/> Nearby
                        </IonItem>
                        <IonItem lines={'none'}>
                            <IonIcon src={'/assets/app-icons/star.svg'}/> Following
                        </IonItem>
                    </React.Fragment> :
                    <React.Fragment>
                        <IonItem lines={'none'}>
                            <a href={marketingSiteUrl}>About</a>
                        </IonItem>
                    </React.Fragment>
                }
                <hr/>
                <p>Login to follow vendors, like posts and view comments.</p>
                <IonButton routerLink={'/welcome/sign-in/phone'}>
                    Sign In
                </IonButton>
                <hr/>
                <WelcomeMenuLink url={'/welcome/terms'}>
                    Terms
                </WelcomeMenuLink>
                <WelcomeMenuLink url={'/welcome/privacy-policy'}>
                    Privacy
                </WelcomeMenuLink>
                <WelcomeMenuLink url={'/welcome/contact-us'}>
                    Contact
                </WelcomeMenuLink>
            </IonContent>
        </IonMenu>
    )
}

export default WelcomeMenu;
