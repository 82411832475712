import React  from 'react';
import {IonRouterOutlet} from '@ionic/react';
import {Route} from 'react-router';
import SignInPhone from './SignIn/SignInPhone';
import ForgotPassword from './ForgotPassword';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfUse from './TermsOfUse';
import AddEmail from './SignUp/EmailStep'
import AddTopics from './SignUp/TopicsStep'
import NameAndPhoneStep from './SignUp/NameAndPhoneStep'

import './index.scss';
import ContactUs from './ContactUs';
import VerificationCodeStep from './SignUp/VerificationCodeStep';
import VerificationCode from './SignIn/VerificationCode';
import NotificationsPrompt from './NotificationsPrompt';
import {Redirect} from 'react-router-dom';
import WelcomeTemplate from '../../components/WelcomeSection/WelcomeTemplate';
import Post from './Post';
import BusinessLocation from './BusinessLocation';
import { Capacitor } from '@capacitor/core';
import Splash from './Splash';

const Welcome: React.FC = () => {

    const entryPoint: string = Capacitor.isNativePlatform() ? "/welcome/splash/home" : "/welcome/sign-in/"

    return (
        <WelcomeTemplate>
            <IonRouterOutlet id={'welcome-section'}>
                <Redirect exact path={'/welcome'} to={entryPoint}/>
                <Redirect exact path={'/welcome/splash/home'} to={entryPoint}/>
                <Route exact path={`/welcome/notifications`} render={() => <NotificationsPrompt/>} />
                <Redirect exact path={'/welcome/sign-in'} to={`/welcome/sign-in/phone`}/>
                <Route exact path={`/welcome/sign-in/phone`} render={() => <SignInPhone />} />
                <Route exact path={`/welcome/sign-in/verification-code`} render={() => <VerificationCode/>} />
                <Route exact path={`/welcome/sign-up/name-phone`} render={() => <NameAndPhoneStep/>} />
                <Route exact path={`/welcome/sign-up/verification-code`} render={() => <VerificationCodeStep/>} />
                <Route exact path={`/welcome/sign-up/email`} render={() => <AddEmail/>} />
                <Route exact path={`/welcome/sign-up/topics`} render={() => <AddTopics/>} />
                <Redirect exact path={'/welcome/sign-up'} to={`/welcome/sign-up/name-phone`}/>
                <Route exact path={`/welcome/forgot-password`} render={() => <ForgotPassword/>} />
                <Route exact path={`/welcome/privacy-policy`} render={() => <PrivacyPolicy/>} />
                <Route exact path={`/welcome/terms`} render={() => <TermsOfUse/>} />
                <Route exact path={`/welcome/contact-us`} render={() => <ContactUs/>} />
                <Redirect exact path={'/welcome/splash'} to={entryPoint}/>
                <Route exact path={`/welcome/splash/:section`} component={Splash} />
                <Route exact path={`/welcome/post/:postId`} render={() => <Post/>} />
                <Route exact path={`/welcome/business/:businessId/location/:locationId`} render={() => <BusinessLocation/>} />

                {/* Invitation codes are no longer required for registration. Keep redirects in case of active links floating around. */}
                <Redirect exact path={`/welcome/sign-up/invitation-code`} to={"/welcome/sign-up"} />
                <Redirect exact path={`/welcome/sign-up/invitation-code/:token`} to={"/welcome/sign-up"} />
            </IonRouterOutlet>
        </WelcomeTemplate>
    );
};

export default Welcome;
