import React from 'react';
import './index.scss';
import Category from "../../models/category";
import {IonItem, IonList} from "@ionic/react";
import MeContextProvider, {MeContext, MeContextStateConsumer} from "../../contexts/MeContext";
import {
    UserFollowsContext,
    UserFollowsContextProvider,
    UserFollowsContextState
} from "../../contexts/UserFollowsContext";
import FollowButton from "../MembersPages/FollowButton";
import {filterUnique} from "../../models/base-model";


interface TopicsListItemProps {
    topic: Category;
    includeLink: boolean;
    userFollowsContext: UserFollowsContextState;
    meContext: MeContextStateConsumer;
}

const TopicsListItem: React.FC<TopicsListItemProps> = ({topic, includeLink, userFollowsContext, meContext}) => {

    const addUserCategory = (meContext: MeContextStateConsumer, category: Category) => {
        const newCategories = [...(meContext.me.followed_categories ?? []), category]
        meContext.setMe({
            ...meContext.me,
            followed_categories: filterUnique(newCategories),
        })
    }

    const removeUserCategory = (meContext: MeContextStateConsumer, category: Category) => {
        const newCategories =
            [...(meContext.me.followed_categories ?? [])].filter(i => i.id != category.id)
        meContext.setMe({
            ...meContext.me,
            followed_categories: newCategories,
        })
    }

    return (
        <IonItem className={'topic-item'} lines={'none'} routerLink={includeLink ? '/home/dashboard/topic/' + topic.id : undefined} detail={false}>
            <div className={'four-square'}>
                <div></div>
                <div></div>
            </div>
            {topic.name}
            <FollowButton
                relatedId={topic.id!}
                relatedType={'category'}
                related={topic}
                userFollowsContext={userFollowsContext}
                onFollow={() => addUserCategory(meContext, topic)}
                onUnfollow={() => removeUserCategory(meContext, topic)}
            />
        </IonItem>
    )
}

interface TopicsListProps {
    topics: Category[];
    includeLink?: boolean
}
const TopicsList: React.FC<TopicsListProps> = ({topics, includeLink = true}) => (

    <MeContextProvider optional hideLoadingSpace>
        <MeContext.Consumer>
            {meContext =>
                <UserFollowsContextProvider userId={meContext.me.id!}>
                    <UserFollowsContext.Consumer>
                        {userFollowsContext => userFollowsContext.initialLoadComplete ? (
                            <IonList className={'topics-list'}>
                                {topics.map((topic, index) => <TopicsListItem key={index} topic={topic} includeLink={includeLink} meContext={meContext} userFollowsContext={userFollowsContext} />)}
                            </IonList>
                        ) : null }
                    </UserFollowsContext.Consumer>
                </UserFollowsContextProvider>
            }
        </MeContext.Consumer>
    </MeContextProvider>
)

export default TopicsList;
