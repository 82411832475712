
import React, {useEffect, useState} from 'react'
import './index.scss'
import SignUpPageStep from "../../../../components/WelcomeSection/SignUpPageStep";
import {useHistory} from 'react-router-dom';
import {TopicsContext, TopicsContextProvider} from "../../../../contexts/TopicsContext";
import TopicsList from "../../../../components/TopicsList";
import MeContextProvider, {MeContext, MeContextStateConsumer} from "../../../../contexts/MeContext";
import {IonToast} from "@ionic/react";

interface TopicsStepContentProps {
    meContext: MeContextStateConsumer
    setTopicsSelected: (count: number) => void
}

const TopicsStepContent: React.FC<TopicsStepContentProps> = ({meContext, setTopicsSelected}) => {
    const [timer, setTimer] = useState<NodeJS.Timeout|undefined>(undefined)

    useEffect(() => {
        const categoriesFollowed = meContext.me.followed_categories ? meContext.me.followed_categories.length : 0

        // meContext.me.followed_categories sets 4 times for each change, this prevents our state from resetting each time it resets.
        // This repeated setting is due to a flaw in how the followed_categories system synchronizes the following status
        // The length of the array reports as N, N+1, N, N+1 in four consecutive calls of this effect. We only care about the final result
        clearTimeout(timer)
        setTimer(setTimeout(() => setTopicsSelected(categoriesFollowed)))
    }, [meContext.me.followed_categories?.length]);

    return (
        <TopicsContextProvider>
            <TopicsContext.Consumer>
                {topicsContext =>
                    <TopicsList topics={topicsContext.loadedData} includeLink={false} />
                }
            </TopicsContext.Consumer>
        </TopicsContextProvider>
    )
}


const TopicsStep: React.FC = () => {
    const history = useHistory();
    const [topicsSelected, setTopicsSelected] = useState(0)
    const [showTopicsAlert, setShowTopicsAlert] = useState(false)
    const [topicsSelectedInitialized, setTopicsSelectedInitialized] = useState(false)
    const requiredTopicSelection = 3

    const onNextStep = () => {
        history.push('/welcome/sign-up/date-of-birth');
    }

    useEffect(() => {
        if (topicsSelected < requiredTopicSelection) {
            // Avoid showing progress alert on page load
            if (!topicsSelectedInitialized) {
                setTopicsSelectedInitialized(true)
            }
            else {
                setShowTopicsAlert(true)
            }
        }
    }, [topicsSelected])

    return (
        <SignUpPageStep
            cancelOnBack
            header={'What topics interest you?'}
            onNextStep={onNextStep}
            className={'topics-signup-step'}
            stepValid={topicsSelected >= requiredTopicSelection}
        >
            <MeContextProvider optional hideLoadingSpace>
                <MeContext.Consumer>
                    {meContext =>
                        <TopicsStepContent meContext={meContext} setTopicsSelected={setTopicsSelected}/>
                    }
                </MeContext.Consumer>
            </MeContextProvider>

            <IonToast
                isOpen={showTopicsAlert}
                duration={2000}
                onDidDismiss={() => setShowTopicsAlert(false)}
                message={`Select at least ${requiredTopicSelection - topicsSelected} more topic${requiredTopicSelection - topicsSelected > 1 ? 's' : ''}`}
            />
        </SignUpPageStep>
    )
}

export default TopicsStep;
