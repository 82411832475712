import React from 'react';
import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonToolbar} from '@ionic/react';
import "./index.scss"
import {arrowForward} from 'ionicons/icons';
import {isAndroid, isIOS} from '../../../util/platform';
import SideBySide from '../../SideBySide';
import ApplicationLogo from '../../ApplicationLogo';
import AppStoreLinks from '../../AppStoreLinks';
import {Capacitor} from '@capacitor/core';

interface NotLoggedInModalProps extends React.ComponentProps<typeof IonModal> {
    abilityMessage: string,
}

const NotLoggedInModal: React.FC<NotLoggedInModalProps> = ({abilityMessage, ...rest}) => {

    const isGedditLocalInstallable = (isIOS || isAndroid) && !Capacitor.isNativePlatform();

    const dismiss = () => rest.onDidDismiss ? rest.onDidDismiss({} as any) : {};

    return (
        <IonModal {...rest}>
            <IonHeader className={'not-logged-in-header'}>
                <IonToolbar>
                    <IonButtons slot={'end'}>
                        <a onClick={dismiss}>X</a>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent className={'not-logged-in-content'} scrollY={false}>
                <ApplicationLogo/>
                {isGedditLocalInstallable ?
                    <div>
                        <h1>Install Geddit Local to {abilityMessage}!</h1>
                        <AppStoreLinks
                            onlyAndroid={isAndroid}
                            onlyIos={isIOS}
                        />
                    </div> :
                    <div>
                        <h1>Sign In or Sign Up to {abilityMessage}!</h1>
                        <SideBySide>
                            <IonButton routerLink={'/welcome/sign-in/phone'} onClick={dismiss}>Sign In</IonButton>
                            <IonButton routerLink={'/welcome/sign-up/name-phone'} onClick={dismiss}>Sign Up</IonButton>
                        </SideBySide>
                    </div>
                }
            </IonContent>
        </IonModal>
    );
}

export default NotLoggedInModal;
