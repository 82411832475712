import React, {useEffect, useRef, useState} from 'react'
import {IonButton, IonContent, IonPage} from '@ionic/react';

import './index.scss';
import {Link, useHistory} from 'react-router-dom';
import Footnote from "../../../components/Footnote";
import GeoLocationContextProvider from '../../../components/GeoLocationContextProvider';
import {connect} from '../../../data/connect';
import {Capacitor} from '@capacitor/core';
import {useLocation} from 'react-router';
import SideBySide from '../../../components/SideBySide';
import AppStoreLinks from '../../../components/AppStoreLinks';
import {isElementInViewport} from '../../../util/view';

interface StateProps {
	hasRespondedToLocationPrompt: boolean,
}

const MobileSplash: React.FC<StateProps> = ({hasRespondedToLocationPrompt}) => {

	const [loginUrl, setLoginUrl] = useState<string|undefined>(undefined);
	const history = useHistory();

	const goToLoginPage = (url: string) => {
		if (hasRespondedToLocationPrompt || !Capacitor.isNativePlatform()) {
			history.push(url);
		} else {
			setLoginUrl(url);
		}
	}

	const locationResponseGiven = (actionTaken: boolean) => {
		if (actionTaken) {
			history.push(loginUrl!);
		}
		setLoginUrl(undefined);
	}

	return (
		<IonContent scrollY={false} className={'mobile-splash hide-in-desktop'}>
			<GeoLocationContextProvider
				promptPermission={!!loginUrl}
				privacyPolicyURL={'/welcome/privacy-policy'}
				onResponseGiven={locationResponseGiven}
			/>
			<div id={'splash-content'}>
				<div id={'headline-wrapper'}>
					<div className={'main-logo'}>
						<img src={'/assets/main-logo-shadow.png'}/>
					</div>
					<div className={'title-header'}>
						<h3 className={'barlow'}>GEDDIT LOCAL</h3>
						<p>Your Community, In Your Pocket</p>
					</div>
				</div>
				<div id={'actions'}>
					<IonButton
						onClick={() => goToLoginPage('/welcome/sign-up/name-phone')}
					>
						Create Account
					</IonButton>
					<p id={'sign-in-line'}>
						<a
							onClick={() => goToLoginPage('/welcome/sign-in/phone')}
						>
							Sign in
						</a>
					</p>
					<hr/>
					<Footnote>
						By using this app, you agree to the <Link to='/welcome/terms'>Terms</Link> and <Link to='/welcome/privacy-policy'>Privacy Policy</Link>
					</Footnote>
				</div>
			</div>
		</IonContent>
	)
}

const DesktopSplash: React.FC = () => {

	const getCurrentSection = () => window.location.pathname.split('/').pop();
	const homeSection = useRef<HTMLDivElement>(null);
	const aboutSection = useRef<HTMLDivElement>(null);
	const [section, setSection] = useState(getCurrentSection());
	const [manuallyNavigated, setManuallyNavigated] = useState(false);
	const location = useLocation();

	const contentScrolled = () => {
		const url = '/welcome/splash/' + (isElementInViewport(aboutSection.current!) ? 'about' : 'home');
		if (url != window.location.pathname) {
			setManuallyNavigated(true);
			window.history.replaceState(null, '', url);
			window.dispatchEvent(new Event('splash-navigated'));
		}
	}

	useEffect(() => {
		window.addEventListener('splash-navigated', () => {
			setSection(getCurrentSection());
		})
	}, [])

	useEffect(() => {
		const newSection = getCurrentSection();
		if (['about', 'home'].indexOf(newSection ?? '') !== -1) {
			setSection(newSection);
		}
	}, [window.location.pathname]);

	useEffect(() => {
		if (aboutSection.current && homeSection.current) {
			if (!manuallyNavigated) {
				const scrollOptions: ScrollIntoViewOptions = {behavior: 'smooth'}
				if (section == 'home' && homeSection.current.scrollIntoView) {
					homeSection.current.scrollIntoView(scrollOptions);
				} else if (section == 'about' && aboutSection.current.scrollIntoView) {
					aboutSection.current.scrollIntoView(scrollOptions);
				}
			}
			setManuallyNavigated(false);
		}
	}, [section, homeSection.current, aboutSection.current])

	return (
		<IonContent
			scrollEvents
			className={'desktop-splash hide-in-mobile'}
			onScroll={contentScrolled}
		>
			<div className={'home-section'} ref={homeSection}>
				<SideBySide>
					<img src={'/assets/web-splash/home-screen-shot.png'}/>
					<div className={'content'}>
						<h2>Your Community, In Your Pocket</h2>
						{!Capacitor.isNativePlatform() &&
							<AppStoreLinks/>
						}
					</div>
				</SideBySide>
			</div>
			<div className={'about-section'} ref={aboutSection}>
				<SideBySide>
					<img src={'/assets/web-splash/about-screen-shot.png'}/>
					<div className={'content'}>
						<h2>Create your free account, then browse the latest content from the community.</h2>
						<p>All participating businesses are homegrown and locally owned.</p>
						<IonButton routerLink={'/welcome/sign-up/name-phone'}>Sign up for free</IonButton>
					</div>
				</SideBySide>
			</div>
		</IonContent>
	)
}


interface SplashProps extends StateProps {}

const Splash: React.FC<SplashProps> = ({hasRespondedToLocationPrompt}) => {

	return (
		<IonPage id={'splash-page'}>
			<MobileSplash hasRespondedToLocationPrompt={hasRespondedToLocationPrompt}/>
			<DesktopSplash/>
		</IonPage>
	)
}

export default connect<{} , StateProps, {  }>({
	mapStateToProps: (state) => ({
		hasRespondedToLocationPrompt: state.persistent.hasRespondedToLocationPrompt
	}),
	component: Splash
});
