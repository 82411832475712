import React from 'react';
import './index.scss';
import {IonButton, IonButtons, IonHeader, IonToolbar} from '@ionic/react';
import {marketingSiteUrl} from "../../../config";


const WelcomeHeader: React.FC = () => {
	return (
		<IonHeader mode={'ios'} className={'welcome-header hide-in-mobile'}>
			<IonToolbar>
				<IonButtons slot={'start'}>
					<a href={marketingSiteUrl}>
						<img
							className='logo hide-in-mobile'
							src='/assets/web-splash/geddit-local-logo.svg'
							alt="Geddit Local"
						/>
					</a>
				</IonButtons>
				<IonButtons slot={'end'} className={'hide-in-mobile'}>
					<IonButton className={'btn-sign-up'} routerLink={'/welcome/sign-up/name-phone'}>
						Sign Up
					</IonButton>
					<IonButton routerLink={'/welcome/sign-in/phone'}>Sign In</IonButton>
				</IonButtons>
			</IonToolbar>
		</IonHeader>
	);
}

export default WelcomeHeader
